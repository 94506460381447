import { PATH } from './constants/router';
import React, { createContext, useState } from 'react';
const BookingPartner = React.lazy(() => import('./page/BookingPartner/index'))
const CheckVihcle = React.lazy(() => import('./page/CheckVihcle/index'))
const BookingInsurancePartner = React.lazy(() => import('./page/BookingInsurancePartner/index'))
const BookingInsuranceSaladin = React.lazy(() => import('./page/BookingInsuranceSaladin/index'))
const BookingPartnerIframe = React.lazy(() => import('./page/Booking/index'))
const BookingHistory = React.lazy(() => import('./page/BookingHistory/index'))
const HomePage = React.lazy(() => import('./page/Home/HomeLayout2/index'))
const HomePageLogin = React.lazy(() => import('./page/Login/index'))
const ResetPassword = React.lazy(() => import('./page/ResetPassword'))
const MyBookingHistory = React.lazy(() => import('./page/MyBookingHistory/index'))
const BookingDetail = React.lazy(() => import('./page/BookingDetail/index'))
const PartnerGuide = React.lazy(() => import('./page/Guide/partnerGuide'))
const PersonalGuide = React.lazy(() => import('./page/Guide/personalGuide'))
const UpdateBookingDetail = React.lazy(() => import('./page/BookingDetail/UpdateBookingDetail'))

const BookingType=process.env.REACT_APP_BHTNDS

const handleCheckPage=()=>{
    switch (BookingType) {
        case '0':
            return (BookingPartner)
        case '1':
            return (BookingInsurancePartner)
        case '2':
            return (BookingInsuranceSaladin)
        default:
            return (BookingPartner)
    }
}

export const ROUTERS = {
    //page on ZALO app
    homePage: {
        path: PATH.HOME,
        component: HomePage,
        isZaloApp: 1
    },
    homeLogin: {
        path: PATH.LOGIN,
        component: HomePageLogin,
        isZaloApp: 1
    },
    bookingDetail: {
        path: PATH.BOOKING_DETAIL,
        component: BookingDetail,
        isZaloApp: 1
    },
    checkVihcleZalo: {
        path: PATH.CHECK_VIHCLE,
        component:CheckVihcle,
        isZaloApp: 1,
    },
    booking: {
        path: PATH.BOOKING,
        component:handleCheckPage(),
        isZaloApp: 1,
    },
    myBookingHistory: {
        path: PATH.MY_BOOKING_HYSTORY,
        component: MyBookingHistory,
        isZaloApp: 1,
    },
    resetPassword: {
        path: PATH.RESET_PASSWORD,
        component: ResetPassword,
        isZaloApp: 1
    },
    updateBookingOnMiniApp: {
        path: PATH.BOOKING_UPDATE,
        component: UpdateBookingDetail,
        isZaloApp: 1,
    },
    // page on web
    checkVihcle: {
        path: PATH.HOME,
        component:CheckVihcle,
        isZaloApp: 0,
    },
    bookingHome: {
        path: PATH.BOOKING,
        component:handleCheckPage(),
        isZaloApp: 0,
    },
    bookingHistory: {
        path: PATH.BOOKING_HYSTORY,
        component: BookingHistory,
        isZaloApp: 0
    },
    partnerGuide: {
        path: PATH.PARTNER_GUIDE,
        component:PartnerGuide,
        isZaloApp: 0,
    },
    personalGuide: {
        path: PATH.PERSONAL_GUIDE,
        component:PersonalGuide,
        isZaloApp: 0,
    },
    bookingDetailNoId: {
        path: PATH.BOOKING_DETAIL_NO_ID,
        component: BookingDetail,
        isZaloApp: 0
    },
    updateBookingOnWeb: {
        path: PATH.BOOKING_UPDATE,
        component: UpdateBookingDetail,
        isZaloApp: 0,
    },
    // bookingPartnerIframe: {
    //     path: PATH.BOOKING_PARTNER_IFRAME,
    //     component: BookingPartnerIframe,
    //     isZaloApp: false
    // },
};
const isZaloApp = process.env.REACT_APP_ZALO_AUTH_ENABLE * 1
export const fillterRoutes = Object.entries(ROUTERS)
    .filter(([key, route]) => route.isZaloApp === isZaloApp)
    .reduce((obj, [key, route]) => {
        obj[key] = route;
        return obj;
    }, {});

